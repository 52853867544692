import { HttpClientModule } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { EnvConfig } from './app/shared/models/env-config.model';
import { environment } from './environments/environment';

fetch(environment.configUrl, { method: 'get', cache: 'no-cache' })
  .then(response => {
    response
      .json()
      .then((envConfig: EnvConfig) => {
        if (envConfig.production) {
          enableProdMode();
        }

        bootstrapApplication(AppComponent, {
          providers: [
            provideAnimations(),
            provideRouter(appRoutes),
            /** Other Modules */
            importProvidersFrom(HttpClientModule),
            /** DI providers */
            { provide: EnvConfig, useFactory: () => new EnvConfig(envConfig) }
          ]
        }).catch(err => console.error(err));
      })
      .catch(err => console.error(err));
  })
  .catch(err => console.error(err));
