import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./views/home/home.component').then(c => c.HomeComponent)
  },
  {
    path: 'about-us',
    children: [
      {
        path: '',
        loadComponent: () => import('./views/about-us/about-us.component').then(c => c.AboutUsComponent)
      }
      // {
      //   path: 'leadership',
      //   loadComponent: () => import('./views/leadership/leadership.component').then(c => c.LeadershipComponent)
      // }
    ]
  },
  {
    path: 'facilities',
    loadComponent: () => import('./views/facilities/facilities.component').then(c => c.FacilitiesComponent)
  },
  {
    path: 'clinicians',
    loadComponent: () => import('./views/clinicians/clinicians.component').then(c => c.CliniciansComponent)
  },
  {
    path: 'apply',
    loadComponent: () => import('./views/apply/apply.component').then(c => c.ApplyComponent)
  },
  {
    path: 'news',
    children: [
      { path: '', loadComponent: () => import('./views/news/news.component').then(c => c.NewsComponent) },
      { path: '2021-05-04', loadComponent: () => import('./views/news/news-2021-05-04/news-2021-05-04.component').then(c => c.News20210504Component) },
      { path: '2020-10-20', loadComponent: () => import('./views/news/news-2020-10-20/news-2020-10-20.component').then(c => c.News20201020Component) },
      { path: '2020-08-31', loadComponent: () => import('./views/news/news-2020-08-31/news-2020-08-31.component').then(c => c.News20200831Component) },
      { path: '2020-06-23', loadComponent: () => import('./views/news/news-2020-06-23/news-2020-06-23.component').then(c => c.News20200623Component) },
      { path: '2020-06-15', loadComponent: () => import('./views/news/news-2020-06-15/news-2020-06-15.component').then(c => c.News20200615Component) },
      { path: '2020-05-26', loadComponent: () => import('./views/news/news-2020-05-26/news-2020-05-26.component').then(c => c.News20200526Component) },
      { path: '2020-04-22', loadComponent: () => import('./views/news/news-2020-04-22/news-2020-04-22.component').then(c => c.News20200422Component) },
      { path: '2019-08-26', loadComponent: () => import('./views/news/news-2019-08-26/news-2019-08-26.component').then(c => c.News20190826Component) },
      { path: '2019-07-17', loadComponent: () => import('./views/news/news-2019-07-17/news-2019-07-17.component').then(c => c.News20190717Component) },
      { path: '2018-10-01', loadComponent: () => import('./views/news/news-2018-10-01/news-2018-10-01.component').then(c => c.News20181001Component) },
      { path: '2018-05-05', loadComponent: () => import('./views/news/news-2018-05-05/news-2018-05-05.component').then(c => c.News20180505Component) },
      { path: '2018-02-05', loadComponent: () => import('./views/news/news-2018-02-05/news-2018-02-05.component').then(c => c.News20180205Component) },
      { path: '2017-12-10', loadComponent: () => import('./views/news/news-2017-12-10/news-2017-12-10.component').then(c => c.News20171210Component) }
    ]
  },
  {
    path: 'contact',
    loadComponent: () => import('./views/contact/contact.component').then(c => c.ContactComponent)
  },
  {
    path: 'sign-up',
    loadComponent: () => import('./views/sign-up/sign-up.component').then(c => c.SignUpComponent)
  },
  {
    path: 'terms-of-service',
    loadComponent: () => import('./views/terms-of-service/terms-of-service.component').then(c => c.TermsOfServiceComponent)
  },
  {
    path: 'privacy-policy',
    loadComponent: () => import('./views/privacy-policy/privacy-policy.component').then(c => c.PrivacyPolicyComponent)
  },
  {
    path: 'events',
    loadComponent: () => import('./views/conference-events/conference-events.component').then(c => c.ConferenceEventsComponent)
  },
  {
    path: 'tennessee',
    loadComponent: () => import('./views/tennessee/tennessee.component').then(c => c.TennesseeComponent)
  },
  {
    path: 'tyler',
    loadComponent: () => import('./views/tyler/tyler.component').then(c => c.TylerComponent)
  },
  {
    path: 'reference/:credentialExtId',
    loadComponent: () => import('./views/reference-request/reference-request.component').then(c => c.ReferenceRequestComponent)
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];
