<div class="container-fluid px-3">
  <div class="flex flex-wrap my-4 relative -mx-3">
    <div class="sadiant-logo"></div>
    <div class="w-12 text-center px-3">
      <div (click)="goToExternal('https://www.facebook.com/sadiant')" class="social-media facebook"></div>
      <div (click)="goToExternal('https://twitter.com/Sadiant_Health')" class="social-media twitter"></div>
      <div (click)="goToExternal('https://www.instagram.com/sadianthealth')" class="social-media instagram"></div>
      <div (click)="goToExternal('https://www.linkedin.com/company/sadiant-health')" class="social-media linkedin"></div>
    </div>
    <div class="w-12 links text-center mb-3 px-3">
      <span [routerLink]="'/terms-of-service'" class="link">Terms of Service</span>
      <span class="bullet"></span>
      <span [routerLink]="'/privacy-policy'" class="link">Privacy Policy</span>
      <span class="bullet"></span>
      <span (click)="goToExternal('https://careers.sadianthealth.com')" class="link">Careers</span>
    </div>
    <div class="w-12 rights text-center px-3">
      &copy; {{year}} Sadiant Health. All rights reserved.
    </div>
  </div>
</div>