import { trigger, transition, style, animate } from '@angular/animations';

export const menuComponentAnimation = trigger(
  'menuAnimation',
  [
    transition(
      ':enter',
      [
        style({ height: 0 }),
        animate('200ms ease-out',
          style({ height: '441px' }))
      ]
    ),
    transition(
      ':leave',
      [
        style({ height: '441px' }),
        animate('200ms ease-in',
          style({ height: 0 }))
      ]
    )
  ]
);