import { CommonModule, NgClass } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { fromEvent } from 'rxjs';
import { EnvConfig } from '../../shared/models/env-config.model';
import { menuComponentAnimation } from './menu.component.anm';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [menuComponentAnimation],
  standalone: true,
  imports: [RouterModule, NgClass, CommonModule]
})
export class MenuComponent implements OnInit, AfterViewInit {
  isHamburgerOpen: boolean = false;
  private breakpointLg: number = 992;
  clientPortalUrl!: string;
  clinicianPortalUrl!: string;
  constructor(private router: Router, private envConfig: EnvConfig) {}

  ngOnInit(): void {
    this.clientPortalUrl = this.envConfig.clientPortalUrl;
    this.clinicianPortalUrl = this.envConfig.clinicianPortalUrl;

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        this.handleWindowSize(window);
      }
    });
    fromEvent(window, 'resize').subscribe(evt => {
      const window = evt.target as Window;
      this.handleWindowSize(window);
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.handleWindowSize(window));
  }

  private handleWindowSize(window: Window) {
    this.isHamburgerOpen = window.innerWidth >= this.breakpointLg;
  }

  openCloseHamburger(): void {
    this.isHamburgerOpen = !this.isHamburgerOpen;
  }

  stopParentRouterLink(e: Event): void {
    e.stopPropagation();
  }
}
